import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import ReleasesGrid from '../components/releasesGrid';
import Helmet from 'react-helmet';

const styles = theme => ({
	root: {
		textAlign: 'center',
		paddingTop: 80,
	},
});

class Index extends React.Component {

	render () {
		const {classes, data} = this.props;

		return (
			<Layout pageName={'Releases'}>

				<Helmet>
					<title itemProp="name" lang="en">Releases</title>
					<link
						rel="canonical"
						href={`https://felononbass.com/releases`}
					/>
					<meta name="Description" content="That's a list of releases by FelonOnBass. Wow."/>
				</Helmet>

				<div className={classes.root} itemScope itemType={"https://schema.org/MusicGroup"}>
					<meta itemProp={"genre"} content={"Electronic Dance Music"}/>
					<meta itemProp={"email"} content={"FelonOnBass@gmail.com"}/>
					<meta itemProp={"location"} content={"Russia"}/>
					<meta itemProp={"name"} content={"FelonOnBass"}/>


					<ReleasesGrid name={'Albums_'} big releases={data.albums.edges}/>
					<ReleasesGrid name={'Singles_'} releases={data.singles.edges}/>
					<ReleasesGrid name={'Remixes & Covers_'} releases={data.remixes.edges}/>
				</div>
			</Layout>

		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));

export const query = graphql`
  query {
  albums: allReleasesJson(sort: {fields: [releaseDate], order: DESC}, filter: {type: {eq: "album"}}) {
    edges {
      node {
        id
        name
        subName
        releaseDate
        type
        feat
        originalArtist
        license
        commerce
        annotation
        cover
        header
        youtube
        soundcloud
        bandcamp
        bandcampID
        fields {
          slug
        }
      }
    }
  }
  singles: allReleasesJson(sort: {fields: [releaseDate], order: DESC}, filter: {type: {eq: "single"}}) {
    edges {
      node {
        id
        name
        subName
        releaseDate
        type
        feat
        originalArtist
        license
        commerce
        annotation
        cover
        header
        youtube
        soundcloud
        bandcamp
        bandcampID
        fields {
          slug
        }
      }
    }
  }
  
  remixes: allReleasesJson(sort: {fields: [releaseDate], order: DESC}, filter: {type: {regex: "/(cover|remix)/"}}) {
    edges {
      node {
        id
        name
        subName
        releaseDate
        type
        feat
        originalArtist
        license
        commerce
        annotation
        cover
        header
        youtube
        soundcloud
        bandcamp
        bandcampID
        fields {
          slug
        }
      }
    }
  } 
}
`;